import { Link, navigate } from "gatsby"
import React, { useContext } from "react"
import "./style.scss"
import Banner from "../../Banner"
import CustomerContext from "../../../context/customerContext"
import CartContext from "../../../context/cartContext"

const MilitaryHero = () => {
  const { isLensesInCart } = useContext(CartContext)
  const { setMilitaryRx } = useContext(CustomerContext)
  const handleClick = e => {
    // e.preventDefault()
    if (!isLensesInCart) setMilitaryRx(true)
    // navigate(e.target.href)
  }

  return (
    <div className="military-hero">
      <Banner
        bannerText={"🇺🇸 Get 15 % OFF Your Monthly Hubble Subscription 🇺🇸"}
        bannerBG={"purple"}
      />
      <div className="grid-section">
        <p className="subtitle">Every day is veterans day</p>
        <h1 className="gradient-title">Hubble Contacts</h1>
        <p className="discount">Get 15% OFF Your Monthly Subscription</p>
        <p className="description">
          We’re proud to extend this exclusive offer to all members of the U.S.
          Armed Forces, all Veterans, and all Military Family Members.
        </p>
        <Link
          to={isLensesInCart ? "/military" : "/contact-lenses"}
          onClick={handleClick}
          className="btn btn-primary cta-btn"
        >
          Start Your Subscription
        </Link>
      </div>
    </div>
  )
}

export default MilitaryHero
