import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import "./style.scss"
import { subscriptionProducts } from "./mockedData"
import { Link } from "gatsby"
import LensCompare from "../../LensCompare"
import CustomerContext from "../../../context/customerContext"
import WebpImage from '../../WebpImage'
import CartContext from "../../../context/cartContext"

const MilitarySubscriptions = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { setMilitaryRx } = useContext(CustomerContext)

  const closePopup = () => {
    setMilitaryRx(false)
    setIsPopupOpen(false)
  }
  const openPopup = () => {
    setMilitaryRx(true)
    setIsPopupOpen(true)
  }

  return (
    <div className="military-subscriptions-section">
      <div className="military-subscriptions">
        {subscriptionProducts.map(product => {
          return (
            <div className="subscription">
              <WebpImage
                className="product-image"
                fileName={product.image}
                alt={product.alt}
              />
              <h3 className="product-title">{product.title}</h3>
              <p className="product-description">{product.description}</p>
              <p className="product-price">
                <span className="product-price-crossed">{product.price}</span>
                <span className="product-price-sale">{` ${product.salePrice}`}</span>
                <br></br>
                {product.priceDescription}
              </p>
              <CtaButton product={product} className='mobile' setMilitaryRx={setMilitaryRx} />
            </div>
          )
        })}
      </div>
      <div className="discount-info-box">
        <p>
          Plus, receive an <b>exclusive</b> 15% off every monthly subscription order!
        </p>
      </div>
      <div className="military-subscriptions desktop-buttons">
        {subscriptionProducts.map(product => {
          return (
            <CtaButton product={product} className='desktop' setMilitaryRx={setMilitaryRx} />
          )
        })}
      </div>
      <p className="military-compare" onClick={openPopup}>
        Compare Our Contact Lenses
      </p>
      <LensCompare isPopup closePopup={closePopup} isPopupOpen={isPopupOpen} />
    </div>
  )
}

export const CtaButton = ({product, className, setMilitaryRx}) => {
  const { isLensesInCart } = useContext(CartContext)
  const handleClick = e => {
    // e.preventDefault()
    if (!isLensesInCart) setMilitaryRx(true)
    // navigate(e.target.href)
  }
  return (
    <div className={`subscription-button-container ${className}`}>
      <Link
        to={isLensesInCart ? "/military" : product.link}
        onClick={handleClick}
        className="btn btn-primary cta-btn subscription-button"
      >
        Shop {product.title}
      </Link>
    </div>
  )
}

export default MilitarySubscriptions
