export const subscriptionProducts = [
  {
    id: 1,
    image: "Pages/MilitaryPage/Military-LP-Classic-Product-Image.png",
    alt: "Classic by Hubble lenses boxes",
    title: "Classic by Hubble",
    description:
      "Our classic daily lens — it’s affordable, convenient, and comfortable for all-day wear.",
    price: "$19.99/eye",
    salePrice: "$1.00",
    priceDescription: "for your first order",
    link: "/contact-lenses/classic-by-hubble-lenses",
  },
  {
    id: 2,
    image: "Pages/MilitaryPage/Military-LP-Hydro-Product-Image.png",
    title: "Hydro by Hubble",
    alt: "Hydro by Hubble lenses box",
    description:
      "With innovative packaging, Hydro by Hubble is naturally hydrating and has an incredibly smooth lens edge.",
    price: "$24.99/eye",
    salePrice: "$9.99/eye",
    priceDescription: "60% off your first order",
    link: "/contact-lenses/hydro-by-hubble-lenses",
  },
  {
    id: 3,
    image: "Pages/MilitaryPage/Military-LP-SkyHy-Product-Image.png",
    title: "SkyHy by Hubble",
    alt: "SkyHy by Hubble lenses boxes",
    description:
      "As our most breathable lens, SkyHy by Hubble is made from silicone hydrogel, the material preferred by doctors.",
    price: "$29.99/eye",
    salePrice: "$17.99/eye",
    priceDescription: "40% off your first order",
    link: "/contact-lenses/skyhy-by-hubble-lenses",
  },
]
