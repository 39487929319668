export const reviews = {
  military: [
    {
      review_copy:
        "Received mine two days ago. Love them. They feel better than the expensive ones from the eye doctor or other mail order contacts. Customer service is wonderful. I highly recommend.",
      reviewer: "Heather P.",
    },
    {
      review_copy:
        "This is literally the only brand of contacts that works for me. I've tried all of the major brands and was ready to give up. I love my Hubble Contacts. <3",
      reviewer: "Anna H.",
    },
    {
      review_copy:
        "OMG when I put them on I was extremely impressed. I'm in love, I cannot even remotely tell they are in. I will be a forever subscriber. Thank you Hubble!",
      reviewer: "Charlie K.",
    },
    {
      review_copy:
        "Thanks to @hubblecontacts for the SUPER SIMPLE process! I will be a return customer for sure!",
      reviewer: "Ashley",
    },
  ],
}
