import React from "react"
import Layout from "../components/layout"

import MilitaryHero from "../components/Military/MilitaryHero"
import MilitaryMissionStatement from "../components/Military/MilitaryMissionStatement"
import MilitarySubscriptions from "../components/Military/MilitarySubscriptions"
import MilitaryValuePropsSection from "../components/Military/MilitaryValuePropsSection"
import MilitaryHomeCta from "../components/Military/MilitaryHomeCta"
import MilitaryReviewCarousel from "../components/Military/MilitaryReviewCarousel"
import MilitaryVideoModule from "../components/Military/MilitaryVideoModule"
import MilitarySupport from "../components/Military/MilitarySupport"
import { useEffect } from "react"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/pages/military-page.scss"

const MilitaryPage = () => {
  useEffect(() => {
    localStorage.setItem("isMilitary", true)
  }, [])

  return (
    <Layout className="military-page" isLogoHeader={true} isReducedFooter={true}>
      <MilitaryHero />
      <MilitaryMissionStatement />
      <MilitarySubscriptions />
      <MilitarySupport />
      <MilitaryVideoModule
        video={"https://www.youtube.com/embed/sB7f8cYcvyg"}
        alt_text="Woman unboxes her Hubble contacts"
      />
      <MilitaryValuePropsSection />
      <MilitaryHomeCta />
      <MilitaryReviewCarousel color="green" page="military" />
    </Layout>
  )
}

export default MilitaryPage
