import React, { useState } from "react"
import { useEffect } from "react"
import "./style.scss"
import dataLayerEngagement from "../../DataLayerEngagement"
import { StaticImage } from "gatsby-plugin-image"

const MilitaryVideoModule = ({ type, image, video, alt_text }) => {
  const [playVideo, setPlayVideo] = useState(false)
  const [videoSource, setVideoSource] = useState(video)

  useEffect(() => {
    if (playVideo === true) {
      setVideoSource(video + "?autoplay=1&rel=0")
    }
  }, [playVideo])

  const handlePlay = () => {
    setPlayVideo(true)
    dataLayerEngagement(
      "military_page_engagement",
      "3",
      "See Us In Action",
      `Play Video`
    )
  }

  return (
    <div className="video-module" data-type={type}>
      <div className="video-holder">
        <div className="grid-section">
          <div className="video-container">
            {playVideo && (
              <iframe
                src={videoSource}
                title="Hubble Video"
                frameborder="0"
                className={`videoPlayer ${playVideo ? "visible" : "hidden"}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            )}
            <div
              className={`copy-container  ${playVideo ? "hidden" : "visible"}`}
            >
              <div className="copy-holder">
                 <span onClick={handlePlay}>
                  <StaticImage
                    src="../../../images/Pages/Homepage/Play_Button.svg"
                    className="playButton"
                    alt=""
                  />
                </span>

                <h3 className="text h3 white">See Us In Action</h3>
                <p className="text h7 white neue">
                  Take a look at how we make Hubble the most hassle-free contact
                  lens and glasses experience in the world.
                </p>
              </div>
            </div>

            <>
              <StaticImage
                className={`overlay-image desktop ${
                  playVideo ? "hidden" : "visible"
                }`}
                src={
                  "../../../images/Pages/MilitaryPage/MilitaryVideoModule/Military-LP-Video.png"
                }
                alt={alt_text}
              />
              <StaticImage
                className={`overlay-image mobile ${
                  playVideo ? "hidden" : "visible"
                }`}
                src={
                  "../../../images/Pages/MilitaryPage/MilitaryVideoModule/Military-LP-Video.png"
                }
                alt={alt_text}
              />
            </>
          </div>
        </div>
      </div>
      <div className="grid-section">
        <div className="mobile-copy-holder">
          <h3 className="text h3 white">See Us In Action</h3>
          <p className="text h7 white neue">
            Take a look at how we make Hubble the most hassle-free contact lens
            and glasses experience in the world.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MilitaryVideoModule
