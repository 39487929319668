import React from "react"
import "./style.scss"

const MilitaryMissionStatement = () => {
  return (
    <div className="military-mission-statement">
      <p>
        With no-fuss subscription personalization,<br></br> home delivery, and
        hassle-free policies, a Hubble subscription makes ordering contacts
        easy.
      </p>
    </div>
  )
}

export default MilitaryMissionStatement
