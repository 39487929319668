import React, { useEffect, useState } from "react"
import "./style.scss"
import useHasMounted from "../../../utils/useHasMounted"
import { useWindowWidth } from "../../../services/hooks"
import WebpImage from '../../WebpImage'

const militaryThumbnails = [
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealAirForce.png",
    alt: "Seal air force",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealArmy.png",
    alt: "Seal Army",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealNavy.png",
    alt: "Seal Navy",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealMarineCorps.png",
    alt: "Seal Marine Corps",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealCoastGuard.png",
    alt: "Seal Coast Guard",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealSpaceForce.png",
    alt: "Seal Space Force",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealNationalGuardBureau.png",
    alt: "Seal National Guard Bureau",
  },
]

const MilitarySupport = () => {
  const isMounted = useHasMounted()
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  return (
    <div className="military-support">
      <div className="military-support-border">
        <div className="support-image-container">
          {isMounted && (
            <>
              <WebpImage
                className={`support-image ${isMobile ? "mobile" : "desktop"}`}
                fileName={
                  isMobile
                    ? "Pages/MilitaryPage/MilitarySupport/Military-LP-Proudly-Support-mobile.png"
                    : "Pages/MilitaryPage/MilitarySupport/Military-LP-Proudly-Support-desktop.png"
                }
                alt="Soldier with a flag on a shoulder"
              />
            </>
          )}
          <div className="copy-container">
            <p>We proudly support our military.</p>
          </div>
        </div>
        <div className="military-thumbnails">
          {militaryThumbnails.map(image => {
            return (
              <WebpImage
              fileName={image.src}
                alt={image.alt}
                className="military-thumbnails-image"
              />
            )
          })}
        </div>
        <div className="military-cx-email">
          <h4 className="cx-email-title">We’re looking out for you.</h4>
          <div className="cx-email-wrapper">
            <p className="cx-email-text">Have a question? Need help?</p>
            <p className="cx-email-text">Reach out to us at <a href="mailto:military@hubblecontacts.com">military@hubblecontacts.com</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MilitarySupport
