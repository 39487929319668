import React, {useContext} from "react"
import { Link, navigate } from "gatsby"
import "./style.scss"
import CustomerContext from "../../../context/customerContext"
import CartContext from "../../../context/cartContext"

const MilitaryHomeCta = () => {
  const { isLensesInCart } = useContext(CartContext)
  const { setMilitaryRx } = useContext(CustomerContext)

  const handleClick = e => {
    // e.preventDefault()
    if(!isLensesInCart) setMilitaryRx(true)
    // navigate(e.target.href)
  }

  return (
  <div className="military-home-cta">
    <div className="grid-section">
      <h2>Over 400 million lenses sold.</h2>
      <p className="cta-text">
        You can stop shopping around. We’ll be your single point of contacts.
      </p>
      <Link to={isLensesInCart ? "/military" : "/contact-lenses"} onClick={handleClick} className="btn btn-primary cta-btn">
        Start Your Subscription
      </Link>
    </div>
  </div>
)}

export default MilitaryHomeCta
